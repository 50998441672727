"use client";

import { useEffect, useRef, useState } from "react";

const asciiArt4 = (
	<pre className="py-24 text-3xl">
		{`
$$\\   $$\\ 
$$ |  $$ |
$$ |  $$ |
$$$$$$$$ |
\\_____$$ |
      $$ |
      $$ |
      \\__|
`}
	</pre>
);

export default function EightBitLikeBouncyText() {
	const [isBounced, setIsBounced] = useState(false);
	const activeAnimationNonce = useRef(0);

	useEffect(() => {
		const thisNonce = (activeAnimationNonce.current = Math.random());
		let isBounced = false;
		function runInterval() {
			setIsBounced((isBounced = !isBounced));
			if (activeAnimationNonce.current == thisNonce)
				setTimeout(runInterval, Math.random() * (2000 - 1000) + 1000);
		}
		runInterval();
	}, []);

	return (
		<div>
			{isBounced && <div className="h-4" />}
			{asciiArt4}
		</div>
	);
}
